<template>
  <div>
    <InviteForm></InviteForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InviteForm from "@/components/SmartVMS/InviteForm";

export default {
  data: function () {
    return {};
  },
  components: {
    InviteForm
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser"
    })
  },
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
