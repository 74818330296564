<template>
  <div>
    <h1 class="mt-3">Invitation Form</h1>
    <b-form>
      <b-card class="mt-3 bg-surface-2">
        <b-form-group
          id="select-group-visit-type"
          label="Visitor Type:"
          label-for="select-visit-type"
        >
          <b-form-select
            v-model="$v.inviteInfo.visitor_type.$model"
            value-field="name"
            text-field="name"
            @change="handleChange"
            :options="visitorTypeOptions"
            :state="validateInviteInfo('visitor_type')"
            aria-describedby="inviteInfo.visitor_type_feedback"
          ></b-form-select>
          <b-form-invalid-feedback
            class="invalid-feedback"
            id="inviteInfo.visitor_type_feedback"
          >
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-visit-datetime"
          label="Date Time:"
          label-for="input-visit-datetime"
        >
          <date-range-picker
            ref="picker"
            class="w-100"
            v-model="dateRange"
            :locale-data="{
              firstDay: 1,
              format: ''
            }"
            :time-picker-increment="1"
            :timePicker="true"
            :time-picker-seconds="true"
          >
            <template v-slot:input="picker">
              {{ picker.startDate | dateTime }} -
              {{ picker.endDate | dateTime }}
            </template>
          </date-range-picker>
        </b-form-group>
      </b-card>

      <b-card class="mt-3 bg-surface-2">
        <b-form-group
          id="input-group-visitor_email"
          label="Visitor email:"
          label-for="input-visitor_email"
        >
          <b-form-input
            id="input-email"
            v-model="$v.inviteInfo.visitor_email.$model"
            placeholder="example@email.com"
            :state="validateInviteInfo('visitor_email')"
            aria-describedby="inviteInfo.visitor_email_feedback"
            @keyup="handleLowercase"
          ></b-form-input>
          <b-form-invalid-feedback
            v-if="!$v.inviteInfo.visitor_email.required"
            class="invalid-feedback"
            id="inviteInfo.visitor_email_feedback"
          >
            This field is required.
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.inviteInfo.visitor_email.email"
            class="invalid-feedback"
            id="inviteInfo.visitor_email_feedback"
          >
            Needs to be a valid email. Example : example@email.com
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-visitor_name"
          label="Visitor name:"
          label-for="input-visitor_name"
        >
          <b-form-input
            v-model="$v.inviteInfo.visitor_name.$model"
            placeholder="Enter your visitor name"
            :state="validateInviteInfo('visitor_name')"
            aria-describedby="inviteInfo.visitor_name_feedback"
          ></b-form-input>
          <b-form-invalid-feedback
            class="invalid-feedback"
            id="inviteInfo.visitor_name_feedback"
          >
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-contact_number"
          label="Contact Number:"
          label-for="input-contact_number"
        >
          <VuePhoneNumberInput
            v-bind="vuePhone.props"
            v-model="contact_number"
            @input="handleRegexPhoneNumber"
            aria-describedby="formUpdate_contact_number_feedback"
          />
        </b-form-group>

        <b-form-group
          id="input-group-visitor_company"
          label="Visitor company:"
          label-for="input-visitor_company"
        >
          <b-form-input
            v-model="inviteInfo.visitor_company"
            placeholder="Enter your visitor company"
          ></b-form-input>
        </b-form-group>
      </b-card>

      <b-card class="mt-3 bg-surface-2">
        <b-form-group
          id="input-group-invite-remark"
          label="Remark"
          label-for="input-invite-remark"
        >
          <b-form-input
            id="input-invite-remark"
            v-model="inviteInfo.remark"
            placeholder="Enter your remark"
          ></b-form-input>
        </b-form-group>
      </b-card>

      <div class="mt-4 mb-5 d-flex justify-content-start">
        <b-button variant="primary" @click="submitAndInviteVisitor"
          >Submit</b-button
        >
        <b-button class="ml-2" @click="resetForm">Clear</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { required, minLength, email, number } from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";

import DateRangePicker from "vue2-daterange-picker";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import * as imageConversion from "image-conversion";
let convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

import jQuery from "jquery";
let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

export default {
  components: {
    DateRangePicker,
    VuePhoneNumberInput
  },
  props: {
    parents_url: {
      type: String,
      required: false,
      default: "create-invite"
    }
  },
  data: function () {
    let current = moment();
    return {
      dateRange: {
        startDate: current.format("YYYY-MM-DD HH:mm:ss"),
        endDate: current.format("YYYY-MM-DD HH:mm:ss")
      },

      inviteInfo: {
        visitor_type: null,
        visitor_email: null,
        visitor_name: null,
        visitor_company: null,
        visitor_contact_number: null,
        visit_start_date: current.format("YYYY-MM-DD"),
        visit_end_date: current.format("YYYY-MM-DD"),
        visit_start_time: current.format("HH:mm:ss"),
        visit_end_time: current.add(2, "hours").format("HH:mm:ss"),
        remark: null
      },

      formRender: [],
      visitorTypeOptions: [],

      contact_number: null,

      vuePhone: {
        phone: "",
        props: {
          clearable: true,
          defaultCountryCode: "MY",
          preferredCountries: ["SG", "ID"],
          noExample: true,
          translations: {
            countrySelectorLabel: "",
            countrySelectorError: "Error",
            example: "Example:",
            phoneNumberLabel: "Example: 188888888"
          }
        }
      }
    };
  },
  watch: {
    getPreference: {
      deep: true,
      handler: function (newVal) {
        console.log("newValpreference", newVal);
        this.inviteInfo.visitor_type =
          newVal.additional_metadata.vms_preferences_default_visitor_type;

        for (let key in this.visitorTypeOptions) {
          if (
            this.visitorTypeOptions[key].name == this.inviteInfo.visitor_type
          ) {
            let endDate = null;
            var num =
              this.visitorTypeOptions[key].additional_metadata
                .visit_duration_minutes;
            var hours = num / 60;
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if (rhours != 0 && rminutes != 0) {
              endDate = moment()
                .add(rhours, "hours")
                .add(rminutes, "minutes")
                .format("YYYY-MM-DD HH:mm:ss");
            }
            if (rhours != 0 && rminutes == 0) {
              endDate = moment()
                .add(rhours, "hours")
                .format("YYYY-MM-DD HH:mm:ss");
            }
            if (rhours == 0 && rminutes != 0) {
              endDate = moment()
                .add(rminutes, "minutes")
                .format("YYYY-MM-DD HH:mm:ss");
            }

            console.log("endDate", endDate);

            this.dateRange = {
              startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              endDate: endDate
            };
          }
        }

        if (newVal.forms.formData) {
          this.setFormData(newVal.forms.formData);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    getPreference() {
      return this.$store.getters["vmsPreferences2Module/getPreferences"];
    }
  },
  mounted: function () {
    this.getVisitorType();
    this.$store.dispatch("vmsPreferences2Module/getAllPreferences");
  },
  validations: {
    inviteInfo: {
      visitor_type: { required },
      visitor_name: { required },
      visitor_email: { required, email }
    }
  },
  methods: {
    getVisitorType() {
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visitor-type/all/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          this.visitorTypeOptions = [];

          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }
          }
          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key2 in result[validUrl]) {
              result[validUrl][key2].name =
                result[validUrl][key2].name.toUpperCase();

              this.visitorTypeOptions.push(result[validUrl][key2]);
            }

            console.log("visitorTypeOptions", this.visitorTypeOptions);
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    //setForm
    setFormData: function (form_data) {
      let $this = this;

      let layoutType = "normal";
      let obj = JSON.parse(form_data);
      let obj2 = [];

      for (let key in obj) {
        if (
          obj[key].type === "text" ||
          obj[key].type === "textarea" ||
          obj[key].type === "number" ||
          obj[key].type === "autocomplete"
        ) {
          obj[key]["data-layout"] = layoutType;
          if (obj[key].required === false) {
            obj[key].required = true;
          }
        } else if (
          obj[key].type === "radio-group" ||
          obj[key].type === "checkbox-group" ||
          obj[key].type === "select" ||
          obj[key].type === "date"
        ) {
          if (obj[key].required == false) {
            obj[key].required = true;
          }
        }
        obj2.push(obj[key]);
      }
      $this.formRender.push(
        $("#declaration_form").formRender({
          dataType: "json",
          formData: obj2
        })
      );
    },

    resetForm() {
      this.inviteInfo = [];
    },

    handleRegexPhoneNumber() {
      if (this.contact_number != null) {
        this.contact_number = this.contact_number.replace(/-/g, "");
      }
      this.inviteInfo.visitor_contact_number = this.contact_number;
    },

    handleLowercase() {
      if (this.inviteInfo.visitor_email != null) {
        this.inviteInfo.visitor_email =
          this.inviteInfo.visitor_email.toLowerCase();
      }
    },

    handleChange(option) {
      for (let index in this.visitorTypeOptions) {
        if (this.visitorTypeOptions[index].name == option) {
          let endDate = null;
          var num =
            this.visitorTypeOptions[index].additional_metadata
              .visit_duration_minutes;
          var hours = num / 60;
          var rhours = Math.floor(hours);
          var minutes = (hours - rhours) * 60;
          var rminutes = Math.round(minutes);
          if (rhours != 0 && rminutes != 0) {
            endDate = moment()
              .add(rhours, "hours")
              .add(rminutes, "minutes")
              .format("YYYY-MM-DD HH:mm:ss");
          }
          if (rhours != 0 && rminutes == 0) {
            endDate = moment()
              .add(rhours, "hours")
              .format("YYYY-MM-DD HH:mm:ss");
          }
          if (rhours == 0 && rminutes != 0) {
            endDate = moment()
              .add(rminutes, "minutes")
              .format("YYYY-MM-DD HH:mm:ss");
          }

          console.log("endDate", endDate);

          this.dateRange = {
            startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            endDate: endDate
          };
        }
      }
    },

    //validation
    validateInviteInfo(field) {
      let result;
      if (field != null) {
        if (this.$v.inviteInfo[field] != undefined) {
          const { $dirty, $error } = this.$v.inviteInfo[field];
          result = $dirty ? !$error : null;
        } else {
          const { $dirty, $error } = this.$v[field];
          result = $dirty ? !$error : null;
        }

        return result;
      }
    },
    onSubmitValidationInviteForm() {
      let $this = this;
      $this.$v.inviteInfo.$touch();
      if ($this.$v.inviteInfo.$anyError) {
        return false;
      } else {
        return true;
      }
    },

    //invite visitor
    submitAndInviteVisitor: function () {
      let $this = this;
      let validForm = $this.onSubmitValidationInviteForm();
      if (validForm) {
        $this.inviteInfo.visit_start_date = moment(
          $this.dateRange.startDate
        ).format("YYYY-MM-DD");
        $this.inviteInfo.visit_start_time = moment(
          $this.dateRange.startDate
        ).format("HH:mm:ss");
        $this.inviteInfo.visit_end_date = moment(
          $this.dateRange.endDate
        ).format("YYYY-MM-DD");
        $this.inviteInfo.visit_end_time = moment(
          $this.dateRange.endDate
        ).format("HH:mm:ss");

        if (this.contact_number == null) {
          $this.inviteInfo.visitor_contact_number = this.contact_number;
        } else {
          let country_code = $(".country-selector__input").val();
          $this.inviteInfo.visitor_contact_number =
            country_code + this.contact_number;
        }
        console.log("$this.inviteInfo", $this.inviteInfo);

        let API_URL = $this.getAPIServerURL + "/api/visitorservice/invite/";
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, $this.inviteInfo)
          .then((data) => {
            if (data.detail != undefined) {
              //this.formAddErrorMessage = data.detail;
            } else {
              let result = null;
              let invite_data = null;
              let validUrl = "";

              result = data.result;
              if (result !== undefined) {
                outer_loop: for (let key in result) {
                  if (result[key] !== undefined) {
                    validUrl = key;
                    break outer_loop;
                  }
                }
              }
              invite_data = result[validUrl];

              console.log("invite_success", invite_data);

              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "Invite your visitor has been successfully",
                message_type: "success"
              });

              this.$router
                .push({
                  name: "PSIM"
                })
                .catch(() => {});
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } /* else {
        $this.$bvModal.msgBoxOk("Form not completed!", {
          centered: true
        });
        return false;
      } */
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.visitor-face-image {
  width: 100px;
  height: 100px;

  background-color: var(--colour-bg);
  border-radius: $border-radius-card;
  border: 3px solid $white;
  overflow: hidden;
  cursor: pointer;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.invalid-dateRange-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d53e4c;
}
</style>
